<template>
    <CRow style="height:100% !important">
        <CCol col="12" xl="12" style="height:100% !important">
            <transition name="slide">
                <CCard style="height:100% !important">
                    <CCardBody height="100%">
                        <div class="row d-flex justify-content-end mr-0 mb-3">
                            <div class="col-auto pr-0">
                                <CButton color="success" to="/incidences/add">
                                    <CIcon :content="addIcon" /> {{ $t('button.create') }} {{ $tc('incidences.title') }}
                                </CButton>
                            </div>
                        </div>
                        <CDataTable
                            :items="filteredItems"
                            :fields="fields"
                            :items-per-page="perPage"
                            :itemsPerPageSelect="itemsPerPage"
                            :tableFilter="tableFilter"
                            :table-filter-value.sync="search"
                            :loading="loading"
                            :sorter="tableOptions.sorter"
                            :pagination="tableOptions.pagination"
                            @pagination-change="updatePerPage"
                            addTableClasses="d-print-table table-dropdown-fix"
                            hover
                        >
                            <template #color="{item}" >
                                <td v-bind:style="{backgroundColor:item.color}">
                                    &nbsp;
                                </td>
                            </template>
                            <template #options="{item}" >
                                <CButtonGroup class="m-2">
                                    <CButton color="warning" 
                                        @click="modifyRecord('INCIDENCES', item.id)">
                                        <CIcon :content="editIcon" />
                                    </CButton>
                                    <CButton color="danger" 
                                        @click="deleteRecord('INCIDENCES', item.id)">
                                        <CIcon :content="deleteIcon" />
                                    </CButton>
                                </CButtonGroup>
                            </template>
                        </CDataTable>
                    </CCardBody>  
                </CCard>
            </transition>
        </CCol>
    </CRow>
</template>

<script>
import i18n from "../../i18n";
import { cilPlus, cilZoom, cilPencil, cilTrash } from '@coreui/icons';
import catalog from '../../hooks/catalogs';

export default {
    name: 'Products',
    mixins: [catalog],
    data() {
        return {
            addIcon: cilPlus,
            showIcon: cilZoom,
            editIcon: cilPencil,
            deleteIcon: cilTrash,
            items: [],
            fields: [
                { 
                    key: 'name', 
                    label: i18n.t('fields.name.label'), 
                },
                { 
                    key: 'color', 
                    label: i18n.t('fields.color.label'), 
                },
                {
                    key: 'options',
                    label: i18n.t('button.options'),
                    sorter: false,
                    filter: false,
                    _style: "width:180px !important" 
                }
            ],
            tableFilter: {
                label: i18n.t('search.label'),
                placeholder: i18n.t('search.placeholder'),
                lazy: false,
                external: true
            },
            tableOptions: {
                sorter: true,
                pagination: true
            }
        }
    },
    computed: {
        style(color) {
            return 'background-color: ' + color + ';';
        },
        filteredItems() {
            if (this.items.length > 0) {
                return this.items.filter(i => {
                    return i.name.toLowerCase().includes(this.search.toLowerCase());
                });
            }

            return [];
        }
    },
    mounted() {
        this.getRecords('INCIDENCES');
    }
}
</script>

